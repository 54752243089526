<template>
    <ProductAnatomy v-editable="blok" :data="productAnatomyData" />
</template>

<script setup lang="ts">
import ButtonType from '~/types/ButtonType';
import ProductAnatomy from '~/components/page-building/product-anatomy/ProductAnatomy.vue';
import ProductAnatomyType from '~/types/ProductAnatomyType';
import ProductAnatomyAttributeType from '~/types/ProductAnatomyAttributeType';
import ImageType from '~/types/ImageType';

const props = defineProps({
    blok: {
        type: Object,
        required: true,
    },
    nextBlock: {
        type: Object,
        required: false,
        default: null,
    },
});

const productAnatomyData = computed<ProductAnatomyType>(() => {
    const buttons = props.blok.buttons
        ? props.blok.buttons.map((button: any) => {
              return {
                  label: button.label,
                  style: button.style,
                  url: button.link.cached_url,
                  vEditable: button,
              } as ButtonType;
          })
        : [];

    const attributes = props.blok.attributes
        ? props.blok.attributes.map((attribute: any) => {
              return {
                  heading: attribute.heading,
                  description: richTextToHtml(attribute.description) as string,
                  vEditable: attribute,
              } as ProductAnatomyAttributeType;
          })
        : [];

    return {
        image: {
            title: props.blok.type,
            alt: props.blok.type,
            src: '/images/product-anatomy/' + props.blok.type + '-anatomy.svg',
        } as ImageType,
        heading: props.blok.heading,
        text: richTextToHtml(props.blok.text) as string,
        buttons,
        attributes,
        type: props.blok.type,
        backgroundColor: props.blok.background_color,
        nextBackgroundColor: props.nextBlock
            ? props.nextBlock.background_color
            : null,
        bottomAngle: props.blok.bottom_angle,
        arrowCallout: props.blok.arrow_callout_type
            ? {
                  type: props.blok.arrow_callout_type,
                  text: richTextToHtml(props.blok.arrow_callout_text) as string,
              }
            : null,
    } as ProductAnatomyType;
});
</script>
